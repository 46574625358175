import {MemberWithPunishmentModel} from "./MemberWithPunishmentModel";
import Api from "../../helper/Api";
import {PersonalPunishmentModel} from "../personalPunishment/PersonalPunishmentModel";


export async function getMembersWithPunishments(): Promise<MemberWithPunishmentModel[]> {
    const api = new Api();
    let teamMembers: MemberWithPunishmentModel[] = []
    await api.getTeamMembersWithPunishments()
        .then((res) => {
            res.data.forEach((teamMember: MemberWithPunishmentModel) => {
                teamMembers = [...teamMembers, new MemberWithPunishmentModel(
                    teamMember.id.toString(),
                    teamMember.name,
                    teamMember.teamId,
                    teamMember.personalPunishments,
                )]
            })
        }).catch((err) => console.log(err));

    return teamMembers;
}
//TODO wieso sieht eigentlich ziemlich gleich aus wie getMembersWihtPunishments()??
export async function getMembersWithOpenPunishments(): Promise<MemberWithPunishmentModel[]> {
    const api = new Api();
    let teamMembers: MemberWithPunishmentModel[] = []
    await api.getTeamMembersWithOpenPunishments()
        .then(async (res) => {
            for (const teamMember of res.data) {
                teamMembers = [...teamMembers, new MemberWithPunishmentModel(
                    teamMember.id.toString(),
                    teamMember.name,
                    teamMember.teamId,
                    teamMember.personalPunishments,
                )]
            }
        }).catch((err) => console.log(err));

    return teamMembers;
}

export async function getAllPunishmentsForTeam(): Promise<PersonalPunishmentModel[]> {
    const api= new Api();
    let personalPunishmentsForTeam  : PersonalPunishmentModel[] = [];
    await api.getPersonalPunishmentsForTeam().then((res) => {
        res.data.forEach((personalPunishment: PersonalPunishmentModel) => {
            personalPunishmentsForTeam = [...personalPunishmentsForTeam, new PersonalPunishmentModel(
                personalPunishment.personalPunishmentId,
                personalPunishment.name,
                personalPunishment.amount,
                personalPunishment.teamId,
                personalPunishment.paid,
                personalPunishment.teamMemberId,
                personalPunishment.teamMemberName,
                personalPunishment.createdAt,
                personalPunishment.payDate,
                personalPunishment.createdBy,
                personalPunishment.cashedBy
            )]
        })
    }).catch((err) => console.log(err));

    return personalPunishmentsForTeam;
}