import {Navigate, useLocation} from "react-router-dom";
import {setAuthToken} from "../../helper/setAuthToken";
import React, {ReactNode, useContext} from "react";
import {AuthContext} from "./AuthContextProvider";

interface ProtectedRouteProps {
    children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { logout} = useContext(AuthContext);
    const storedToken = localStorage.getItem('token');
    const location = useLocation();

    if (storedToken) {
        const decodedJwt = parseJwt(storedToken);

        if (decodedJwt.exp * 1000 < Date.now()) {
            logout()
            return <Navigate to="/login" replace state={{ from: location }} />;
        }

        setAuthToken(storedToken);
    }

    if (!storedToken) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;

const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};