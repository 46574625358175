import React, {createContext, ReactNode, useEffect, useState} from 'react';
import Api from "../../helper/Api";
import {setAuthToken} from "../../helper/setAuthToken";
import {useNavigate} from "react-router-dom";

interface AuthContextValue {
    isLoggedIn: boolean | null;
    login: (username: string, password: string) => void;
    setLogin: (token: string) => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextValue>({
    isLoggedIn: null,
    login: (username: string, password: string) => {},
    setLogin: (toke: string) => {},
    logout: () => {},
});

interface AuthContextProviderProps {
    children: ReactNode;
}

const AuthContextProvider: React.FC<AuthContextProviderProps> = ({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
    let api = new Api();
    let navigate = useNavigate();

    const setLogin = (token: string) => {
        setIsLoggedIn(true);
        setAuthToken(token);
        localStorage.setItem("token", token)
    }

    const login = (username: string, password: string) => {
        api.handleLogin(username, password)
            .then((res) => {
                const token = res.data.token;
                setLogin(token);
                navigate("/");
            })
    };

    useEffect(() => {
        // Prüfe, ob ein Token im Local Storage vorhanden ist
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setIsLoggedIn(true);
            setAuthToken(storedToken);
        }
    }, []);

    const logout = () => {
        setIsLoggedIn(false);
        setAuthToken("");
        localStorage.removeItem("token");
    };

    const value: AuthContextValue = {
        isLoggedIn,
        login,
        setLogin,
        logout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
