import React from "react"

const Footer = () => <footer className="navbar bottom">
    <div className="container-fluid text-center text-md-left">
        <div className="row">
            <div className="col-md-6 mb-md-0 mb-3">
                <a href="/impressum">Impressum</a>
            </div>

            <div className="col-md-6 mb-md-0 mb-3">
                <a href="/privacyPolicy">Datenschutz</a>
            </div>
        </div>
    </div>

</footer>

export default Footer