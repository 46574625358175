import axios, {AxiosInstance} from "axios";
import {SERVER_PATH, SERVER_URL} from "../text";
import {TeamMemberModel} from "../components/member/TeamMemberModel";
import {TeamModel} from "../components/TeamModel";
import {PunishmentModel} from "../components/punishment/PunishmentModel";


export default class Api {
    private client: AxiosInstance | undefined;
    private readonly api_url: string;
    constructor() {

        this.api_url = SERVER_PATH;
    }

    init = () => {

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
        };

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    handleLogin = (username: string, password: string) => {
        const loginPayload = {
            username: username,
            password: password
        }
        return this.init().post("/authenticate", loginPayload)
    }

    handleRegister = (email: string, username: string, password: string, matchingPassword: string, team: TeamModel) => {
        const registerPayload = {
            hostUrl: SERVER_URL + "/confirmAccount",
            email: email,
            username: username,
            password: password,
            matchingPassword: matchingPassword,
            team: team
        }
        return this.init().post("/account", registerPayload)
    }

    confirmAccount = (token: string) => {
        return this.init().post("/account/register/confirmAccount?token=" + token)
    }

    resendRegisterToken = (email: string, username: string) => {
        const payload = {
            hostUrl: SERVER_URL + "/confirmAccount",
            email: email,
            username: username,
        }
        return this.init().post("/account/register/resendRegistrationToken", payload)
    }

    changePassword = (password: string, matchingPassword: string) => {
        const payload = {
            password: password,
            matchingPassword: matchingPassword,
        }
        return this.init().post("/account/changePassword", payload)
    }

    getNewToken = () => {
        return this.init().get("/generateNewToken")
    }

    getUser = () => {
        return this.init().get("/account")
    }

    getTeam = () => {
        return this.init().get("/team")
    }

    getTeamMembersWithPunishments = () => {
        return this.init().get("/teamMembers/withPersonalPunishments")
    }

    getTeamMembersWithOpenPunishments = () => {
        return this.init().get("/teamMembers/withOpenPersonalPunishments")
    }

    getPersonalPunishments = (teamMemberId: string) => {
        return this.init().get("/personalPunishment/" + teamMemberId)
    }

    getPersonalPunishmentsForTeam = () => {
        return this.init().get("personalPunishment/team")
    }

    addOrUpdatePersonalPunishments = (personalPunishment: string) => {
        return this.init().post("/personalPunishment", personalPunishment)
    }

    deletePersonalPunishments = (personalPunishmentId: string) => {
        return this.init().delete("/personalPunishment/" + personalPunishmentId)
    }

    getPunishments = () => {
        return this.init().get("/punishments")
    }

    deletePunishment = (punishmentId: string) => {
        return this.init().delete("/punishments/" + punishmentId)
    }

    addOrUpdatePunishment = (punishment: PunishmentModel) => {
        return this.init().post("/punishments", punishment)
    }

    addOrUpdateTeamMembers = (params: TeamMemberModel) => {
        return this.init().post("/teamMembers", JSON.stringify(params))
    }

    deleteTeamMembers = (memberId: string) => {
        return this.init().delete("/teamMembers/" + memberId)
    }
}