import React from "react";
import ChangePasswordForm from "../auth/ChangePasswordForm";

const SettingsPage = () => {
    return (
        <>
            <ChangePasswordForm/>
        </>
    )
}
export default SettingsPage;