import React, {useContext} from 'react';
import './static/css/App.css';
import './static/css/overrideBootstrap.css';

import {Route, Routes, useNavigate} from 'react-router-dom';
import {AuthContext} from './components/auth/AuthContextProvider';
import Home from "./components/Home";
import MemberPage from "./components/member/MemberPage";
import PunishmentPage from "./components/punishment/PunishmentPage";
import PaymentHistory from "./components/PaymentHistory";
import SettingsPage from "./components/settings/SettingsPage";
import Impressum from "./components/footer/Impressum";
import PrivacyPolicy from "./components/footer/PrivacyPolicy";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import LoginForm from "./components/auth/LoginForm";
import Logout from "./components/auth/Logout";
import RegisterForm from "./components/auth/RegisterForm";
import ConfirmAccountForm from "./components/auth/ConfirmAccountForm";
import ResAppBar from "./components/ResAppBar";
import Footer from "./components/footer/Footer";

const App: React.FC = () => {
    const { isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    if (isLoggedIn && window.location.pathname === '/login') {
        navigate('/');
    }

    return (
            <div className="App">
                {isLoggedIn !== null && isLoggedIn !== undefined && isLoggedIn && <ResAppBar/>}
                <div style={{marginTop: 80}}></div>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <Home/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/player"
                        element={
                            <ProtectedRoute>
                                <MemberPage/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/punishments"
                        element={
                            <ProtectedRoute>
                                <PunishmentPage/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/paymenthistory"
                        element={
                            <ProtectedRoute>
                                <PaymentHistory/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/settings"
                        element={
                            <ProtectedRoute>
                                <SettingsPage/>
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/impressum" element={<Impressum/>}/>
                    <Route path="/login" element={<LoginForm/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/privacyPolicy" element={<PrivacyPolicy/>}/>
                    <Route path="/register" element={<RegisterForm/>}/>
                    <Route path="/confirmAccount" element={<ConfirmAccountForm/>}/>
                    <Route path="*" element={<NoMatch/>}/>
                </Routes>
                <Footer/>
            </div>
    );
};

const NoMatch = () => {
    return <p>There's nothing here: 404!</p>;
};

export default App;