import {AccountModel} from "../AccountModel";

export class PersonalPunishmentModel {
    constructor(public personalPunishmentId: string, public name: string, public amount: number, public teamId: string, public paid: boolean, public teamMemberId: string, public teamMemberName: string, public createdAt: Date, public payDate: Date, public createdBy?: AccountModel, public cashedBy?: AccountModel) {
        this.personalPunishmentId = personalPunishmentId;
        this.name = name;
        this.amount = amount;
        this.teamId = teamId;
        this.paid = paid;
        this.teamMemberId = teamMemberId;
        this.teamMemberName = teamMemberName;
        this.createdAt = createdAt;
        this.payDate = payDate;
        this.createdBy = createdBy;
        this.cashedBy = cashedBy;
    }
}