import React, {useEffect, useState} from "react";
import {getAllPunishmentsForTeam} from "./member/MemberHelper";
import {PersonalPunishmentModel} from "./personalPunishment/PersonalPunishmentModel";
import dayjs from "dayjs";
import {Table} from "react-bootstrap";

const PaymentHistory = () => {
    const [personalPunishmentsForTeam, setPersonalPunishmentsForTeam] = useState<PersonalPunishmentModel[]>([])
    const [amount, setAmount] = useState(0)

    useEffect(() => {
        modifyState();
    }, []);

    const compareStrings = (a: string, b: string) => {
        return (a < b) ? -1 : (a > b) ? 1 : 0;
    }
    async function modifyState(): Promise<void> {
        let punishmentsForTeam = await getAllPunishmentsForTeam();
        let payedPunishmentsForTeam = punishmentsForTeam.filter(punishment => punishment.paid).sort((a,b) => compareStrings(dayjs(b.payDate).toISOString(), dayjs(a.payDate).toISOString()));
        setPersonalPunishmentsForTeam(payedPunishmentsForTeam);

        setAmount(payedPunishmentsForTeam.reduce((a, b) => a + b.amount, 0));

    }

    return (
        <>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Datum</th>
                    <th>Spieler</th>
                    <th>Strafe</th>
                    <th>Betrag</th>
                    <th>Kassiert von</th>
                    <th>Erstellt von</th>
                </tr>
                </thead>
                <tbody>
                {personalPunishmentsForTeam.map((personalPunishmentForTeam) => {
                    return (
                        <tr key={personalPunishmentForTeam.personalPunishmentId} className={personalPunishmentForTeam.amount > 0 ? "table-success" : "table-danger"}>
                            <td>
                                {dayjs(personalPunishmentForTeam.payDate).format("DD.MM.YYYY HH:mm:ss")}
                            </td>
                            <td>
                                {personalPunishmentForTeam.teamMemberName}
                            </td>
                            <td>
                                {personalPunishmentForTeam.name}
                            </td>
                            <td>
                                {personalPunishmentForTeam.amount} €
                            </td>
                            <td>
                                {personalPunishmentForTeam.cashedBy != null ? personalPunishmentForTeam.cashedBy.username : ""}
                            </td>
                            <td>
                                {personalPunishmentForTeam.createdBy != null ? personalPunishmentForTeam.createdBy.username : ""}
                            </td>
                        </tr>
                    );
                })
                }
                <tr>
                    <td></td>
                    <td></td>
                    <td >Gesamtbetrag:</td>
                    <td >{amount}</td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </Table>
        </>
    )
}

export default PaymentHistory;
