import React from 'react';
import './static/css/index.css';
import App from './App';
import {createRoot} from 'react-dom/client';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router} from 'react-router-dom';
import AuthContextProvider from "./components/auth/AuthContextProvider";

const element = document.getElementById('root');
const root = createRoot(element!);

root.render(
    <Router>
        <AuthContextProvider>
            <App />
        </AuthContextProvider>
    </Router>
);