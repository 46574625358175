import React, {useContext, useState} from "react";
import {SERVER_PATH} from "../../text";
import {AuthContext} from "./AuthContextProvider";
import '../../static/css/login.css';

const LoginForm: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const { isLoggedIn, login } = useContext(AuthContext);
    const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };
    const handeLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        login(username, password)
    }

    return (
        <div>
            <form className={"login-form"}>
                <div className="login-row">
                    <label>Username</label>
                    <input type={"text"} onChange={handleChangeUsername} placeholder={"Username eingeben"}
                           name="username" autoComplete="current-username"/>
                </div>
                <div className="login-row">
                    <label>Passwort</label>
                    <input type={"password"} onChange={handleChangePassword} placeholder={"Passwort eingeben"}
                           name="password" autoComplete="current-password"/>
                </div>
                <div id="login-button" className="login-row">
                    <button className={"btn btn-success"}
                            onClick={handeLogin}>{SERVER_PATH.includes("localhost") ? "LOCAL-Login" : "Login"}</button>
                </div>
                <center><a href="/register">Noch keinen Account? Hier registrieren!</a></center>
            </form>
        </div>
    )
}
export default LoginForm;