import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Api from "../../helper/Api";
import {SERVER_PATH} from "../../text";

const ConfirmAccountForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [successConfirm, setSuccessConfirm] = useState(false)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const api = new Api();
        api.confirmAccount(token != null ? token : "")
            .then((res) => {
                if (res.status !== 200) {
                    setSuccessConfirm(false)
                } else {
                    setSuccessConfirm(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, [token]);

    if (loading) {
        return <center>Loading...</center>;
    }

    const SuccessConfirm = () => {
        return (
            <>
                <center>
                    <p>Account erfolgreich erstellt</p>
                    <a href="/">Hier anmelden</a>
                </center>
            </>
        )
    };

    const ErrorConfirm = () => {
        const [email, setEmail] = useState('');
        const [username, setUsername] = useState('');
        const handleChangeEmailResend = (e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
        };
        const handleChangeUsernameResend = (e: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(e.target.value);
        };

        const handleResendToken = async () => {
            let api = new Api();
            api.resendRegisterToken(email, username).then(() => {
                navigate("/");
            })
        }
        return (
                    <form className={"login-form"}>
                        <div className="login-row">
                            <label>Email</label>
                            <input type={"text"} onChange={handleChangeEmailResend} placeholder={"Email eingeben"}
                                   name="emailResend"/>
                        </div>
                        <div className="login-row">
                            <label>Username</label>
                            <input type={"text"} onChange={handleChangeUsernameResend} placeholder={"Username eingeben"}
                                   name="usernameResend"/>
                        </div>
                        <div id="login-button" className="login-row">
                            <button className={"btn btn-success"}
                                    onClick={handleResendToken}>{SERVER_PATH.includes("localhost") ? "LOCAL-Resend" : "Token senden"}</button>
                        </div>
                    </form>
        )
    };

    return (
        <>
            {successConfirm ? <SuccessConfirm/> : <ErrorConfirm/>}
        </>
    );
};

export default ConfirmAccountForm;